

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  4: {
    NFT: '0x3E6E89A59dD90857679aCf51BAdc0eD57B8580b1'
  },
  1: {
    NFT: '0xd3a4542fe6cdd6c1930f072b2d168cf506248acc'
  }
};



