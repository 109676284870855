import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import { useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";
import { light as lightTheme } from "./themes/light";
import "./style.scss";
import Loading from './components/loading'
import LoadingSplash from './components/LoadingSplash'
import { loadAppDetails } from "./slices/AppSlice";
import { loadAccountDetails } from "./slices/AccountSlice";
import { batch, useDispatch, useSelector } from "react-redux";
import Messages from "./components/Messages/Messages";

const DEBUG = false;
const Home = lazy(() => import('./views/home'));
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  useTheme();
  const location = useLocation();
  const { connect, connected, address, chainID, provider, hasCachedProvider } = useWeb3Context();
  const [walletChecked, setWalletChecked] = useState(false);
  const dispatch = useDispatch();
  const [pathname, setPathname] = useState(location.pathname)

  async function loadDetails(whichDetails) {
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }

    if (whichDetails === "account" && address && connected) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      batch(() => {
        dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));
      })
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      batch(() => {
        dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }))
      })
    },
    [connected],
  );

  useEffect(() => {
    // don't load ANY details until wallet is Checked
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  useEffect(() => {
    // don't load ANY details until wallet is Connected
    if (connected) {
      loadDetails("account");
    }
  }, [connected]);

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }

    // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
    storeQueryParameters();
  }, []);

  useEffect(() => {
    setPathname(location.pathname)
  }, [location]);

  let themeMode = lightTheme;
  
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  return (
    <ThemeProvider theme={themeMode}>
     <Messages />
      {
        pendingTransactions.length > 0 &&
        <Loading />
      }
      <CssBaseline />
      <Suspense fallback={<LoadingSplash></LoadingSplash>}>
        <div className={`app-container`}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
