import { BigNumber, ethers } from "ethers";
import { addresses } from "../constants";
import { setAll, } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { abi as nftABI } from "../abi/nft.json";
const initialState = {
    loading: false,
    loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
    "app/loadAppDetails",
    async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
        const nftContract = new ethers.Contract(addresses[networkID].NFT, nftABI, provider)
        const minted = await nftContract.minted()
        return {
            minted: Number(minted)
        }
    },
);

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        fetchAppSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
