import { Box } from "@material-ui/core";
import "./loading.scss";
import LoadingGif from '../../assets/images/loading.gif';

export default function LoadingSplash() {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' className="loading-splash">
      <img src={LoadingGif} />
    </Box>
  );
}
