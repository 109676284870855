import { ethers } from "ethers";
import { addresses } from "../constants";
import { setAll } from "../helpers";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { abi as nftABI } from "../abi/nft.json";

export const loadAccountDetails = createAsyncThunk(
    "account/loadAccountDetails",
    async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
        const nftContract = new ethers.Contract(addresses[networkID].NFT, nftABI, provider)
        const userMinted = await nftContract.mintCount(address)
        return {
            userMinted: Number(userMinted)
        }
    },
);

interface IAccountSlice {
    loading: boolean;
}

const initialState: IAccountSlice = {
    loading: false,
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

const baseInfo = (state: RootState) => state.account;
export default accountSlice.reducer;
export const { fetchAccountSuccess } = accountSlice.actions;
export const getAccountState = createSelector(baseInfo, account => account);
